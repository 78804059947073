module.exports = [{
      plugin: require('../plugins/gatsby-plugin-datadog/gatsby-browser.ts'),
      options: {"plugins":[],"applicationId":"7a0ed26b-1240-43bb-98c4-39b02f89078a","clientToken":"pub09985683f5bba97f3db88b225624153f","env":"production","version":"7VsnACxK"},
    },{
      plugin: require('../plugins/gatsby-plugin-preview-options/gatsby-browser.tsx'),
      options: {"plugins":[],"enableInspectorMode":false,"enableLiveUpdates":false},
    },{
      plugin: require('../plugins/gatsby-plugin-espace-client/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-calculette-conso/gatsby-browser.tsx'),
      options: {"plugins":[],"version":"v2","pages":["/pret-personnel-prive"]},
    },{
      plugin: require('../plugins/gatsby-source-netreviews/gatsby-browser.ts'),
      options: {"plugins":[],"apiUrl":"https://cl.avis-verifies.com/fr/cache/6/c/c/6ccce3ac-4a8f-f8a4-f90f-d9e90ef420b0/AWS"},
    },{
      plugin: require('../plugins/gatsby-source-trustpilot/gatsby-browser.ts'),
      options: {"plugins":[],"businessUnitId":"4e95b8f00000640005113693","apiKey":"x4NWuafKJfdZzOKIoHBMAUWEPu4s6wbK","minStars":5},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/fortuneo-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7bd6bbada4f4be62bb60b6ee47597acd"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
