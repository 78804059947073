import '@contentful/live-preview/style.css'

import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import { type GatsbySSR } from 'gatsby'
import React from 'react'

import { PreviewOptionsProvider } from './preview-options-provider'

type PluginOptions = {
  enableInspectorMode: boolean
  enableLiveUpdates: boolean
}

export const wrapRootElement: GatsbySSR['wrapRootElement'] = (
  { element },
  pluginOptions
) => {
  const { enableInspectorMode = false, enableLiveUpdates = false } =
    pluginOptions as unknown as PluginOptions

  return (
    <ContentfulLivePreviewProvider
      enableInspectorMode={enableInspectorMode}
      enableLiveUpdates={enableLiveUpdates}
      locale="fr-FR"
    >
      <PreviewOptionsProvider enableInspectorMode={enableInspectorMode}>
        {element}
      </PreviewOptionsProvider>
    </ContentfulLivePreviewProvider>
  )
}
