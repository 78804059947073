import { ContentType } from '@entries/content-types'
import snakeCase from 'lodash/snakeCase'
import queryString from 'query-string'
import { type SyntheticEvent } from 'react'
import type TC_Wrapper from 'react-tag-commander'

import {
  AMEX_LINK_SUFFIX,
  AMEX_TRACKED_LINK,
  ENV_TEMPLATE_LABEL,
  PAGE_TYPE,
  PAGE_TYPE_URL,
  PRODUCT_ID_TYPE_MAPPING,
  PRODUCT_TYPE_LABEL,
  PRODUCT_URL_MAPPING,
} from './constants'

export const getProductTypeId = (url: string): string => {
  const key = Object.keys(PRODUCT_URL_MAPPING).find((str) =>
    url.startsWith(String(str))
  )

  return key
    ? PRODUCT_ID_TYPE_MAPPING[PRODUCT_URL_MAPPING[key]]
    : PRODUCT_ID_TYPE_MAPPING.default
}

export const getPageType = (url: string, pageData: any) => {
  const pageTypeWithExactUrl = Object.entries(PAGE_TYPE_URL).find(
    ([, value]) => value === url
  )

  const pageTypeWithUrlStartsWith = Object.entries(PAGE_TYPE_URL).find(
    ([, value]) => String(url).startsWith(value)
  )

  const pageTypeWithExactPageUrl = Object.entries(PAGE_TYPE_URL).find(
    ([, value]) => value === pageData?.pageUrl
  )

  if (pageTypeWithExactUrl) {
    return pageTypeWithExactUrl[0] // Key
  }

  if (pageTypeWithUrlStartsWith) {
    return pageTypeWithUrlStartsWith[0] // Key
  }

  if (pageTypeWithExactPageUrl) {
    return pageTypeWithExactPageUrl[0] // Key
  }

  if (pageData?.form?.__typename === ContentType.ContentfulForm) {
    return PAGE_TYPE.LANDING_CCO
  }

  return PAGE_TYPE.CMS
}

// Wrapper.getTcVar is not working
const getTcVar = (key: string | number): string =>
  typeof window !== 'undefined' &&
  Boolean(window.tc_vars) &&
  typeof window.tc_vars[key] !== 'undefined'
    ? window.tc_vars[key]
    : false

export const getEventCategory = (wrapper: TC_Wrapper) => {
  if (wrapper) {
    const envTemplateId = getTcVar('env_template')
    const productId = getTcVar('type_produit')
    const templateLabel = ENV_TEMPLATE_LABEL[envTemplateId]
    const productTypeLabel = PRODUCT_TYPE_LABEL[productId]
    return [templateLabel, productTypeLabel]
      .filter((str) => Boolean(str))
      .join('|')
  }

  return ''
}

type TrackingTargetMetaData = {
  href: string
  dataType: string
  dataRole: string
  defaultLabel: string
  isAmexLinkToTrack: boolean
}

export const getTargetMetaData = (
  event: SyntheticEvent
): TrackingTargetMetaData => {
  const dataType = String(event.currentTarget.getAttribute('data-type'))
  const dataRole = String(event.currentTarget.getAttribute('data-role'))
  const { url } = queryString.parseUrl(
    String(event.currentTarget.getAttribute('href'))
  )
  const isAmexLinkToTrack = AMEX_TRACKED_LINK.includes(url)
  const defaultLabel = snakeCase(event.currentTarget.textContent || undefined)

  return {
    href: url,
    dataType,
    dataRole,
    isAmexLinkToTrack,
    defaultLabel,
  }
}

export const computeAmexLabel = (href: string, label: string): string => {
  const suffix = AMEX_LINK_SUFFIX[href] || null
  return `cta_${label}${suffix ? `_${suffix}` : ''}`
}
