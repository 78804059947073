import React, {
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { PreviewOptionsContext } from './preview-options-context'

export const PreviewOptionsProvider: FC<
  PropsWithChildren & { enableInspectorMode: boolean }
> = ({ enableInspectorMode, children }) => {
  const [isEnabled, setIsEnabled] = useState(false)

  const receiveMessage = useCallback((event: any): void => {
    if (event?.data?.method === 'INSPECTOR_MODE_CHANGED') {
      const { isInspectorActive } = event.data
      setIsEnabled(isInspectorActive)
    }
  }, [])

  useEffect(() => {
    if (enableInspectorMode) {
      window.addEventListener('message', receiveMessage)
      return () => {
        window.removeEventListener('message', receiveMessage)
      }
    }
  }, [enableInspectorMode])

  return (
    <PreviewOptionsContext.Provider value={isEnabled}>
      {children}
    </PreviewOptionsContext.Provider>
  )
}
